import { ImageProps } from "../../pages/api/schemas/general";
import { articleTag } from "../../pages/api/schemas/enum";
import Card from "../Card";
import { useState } from "react";

interface PubblicationsProps {
  articles: ArticleProps[];
}

interface ArticleProps {
  title: string;
  position: number;
  image: ImageProps;
  externalLink: string;
  date: string;
  tag: articleTag;
}

const Publications: React.FC<PubblicationsProps> = ({ articles }) => {
  const [selectedTag, setSelectedTag] = useState<string>("all");
  const [visibleArticles, setVisibleArticles] =
    useState<ArticleProps[]>(articles);

  const totalRows = Math.ceil(visibleArticles.length / 2);
  const getCardPosition = (index: number) => {
    index = index + 1;
    let classes = "";
    let rowStart = 0;
    let row = 0;

    row = Math.ceil(index / 2);

    if (index % 2 != 0) {
      rowStart = (row - 1) * 5 + 1;
      classes += "lg:row-span-5 lg:col-start-1";
    } else {
      if (row % 2 != 0) {
        classes += "lg:row-span-6";
        rowStart = (row - 1) * 4 + 1;
      } else {
        classes += "lg:row-span-4";
        rowStart = (row - 1) * 6 + 1;
      }
      classes += " lg:col-start-2";
    }
    classes += ` lg:col-span-1 lg:row-start-${rowStart}`;
    return classes;
  };

  const orderedByDate: any[] = visibleArticles.sort(
    (article1: any, article2: any) => {
      const [currday, currmonth, curryear] = article1.date.split("/");
      const [nextday, nextmonth, nextyear] = article2.date.split("/");
      let curr = new Date(+curryear, +currmonth - 1, +currday);
      let next = new Date(+nextyear, +nextmonth - 1, +nextday);
      if (curr > next) {
        return -1;
      } else if (curr < next) {
        return 1;
      }
      return 0;
    }
  );
  let orderedArticles = [];
  for (let article of orderedByDate) {
    if (article.position !== undefined)
      orderedArticles.splice(article.position - 1, 0, article);
    else {
      orderedArticles.push(article);
    }
  }

  const tags = [
    "all",
    ...Array.from(
      new Set(articles.map((article: ArticleProps) => article.tag))
    ),
  ];

  const handleTagChange = (tag: string) => {
    setSelectedTag(tag);
    if (tag === "all") {
      setVisibleArticles(articles);
    } else {
      const filteredArticles = articles.filter(
        (article) => article.tag === tag
      );
      setVisibleArticles(filteredArticles);
    }
  };

  return (
    <div
      id="publications"
      className="flex w-full object-contain h-full justify-center my-[10rem] md:my-[20rem] pt-[5rem] publications-section"
    >
      <div className="flex flex-col w-5/6 space-y-2">
        <div className="flex flex-row w-full md:w-1/2">
          <div className="flex flex-row -ml-2 space-x-2 overflow-x-auto md:overflow-x-hidden">
            {tags.map((tag) => (
              <a
                key={tag}
                className="magnetic-btn will-change-transform relative inline-block align-middle text-center"
              >
                <span className="relative p-2 inline-block align-middle text-centerease-linear duration-150">
                  <button
                    className={`transition duration-300 ease-in-out rounded-lg cursor-pointer px-3 py-1 ${
                      selectedTag === tag
                        ? "bg-gray text-white"
                        : "bg-transparent text-gray hover:bg-gray hover:text-white"
                    }`}
                    onClick={() => handleTagChange(tag)}
                  >
                    {tag.toUpperCase()}
                  </button>
                </span>
              </a>
            ))}
          </div>
        </div>
        <div
          className="grid grid-rows-publications grid-flow-col grid-cols-publications gap-20 md:gap-10"
          style={{ gridTemplateRows: `repeat(${totalRows * 5}, 10vh)` }}
        >
          {orderedArticles.map((article: any, index: number) => {
            return (
              <Card
                key={index}
                image={article.image}
                title={article.title}
                date={article.date}
                tag={article.tag}
                url={article.externalLink}
                className={`${getCardPosition(
                  index
                )} transition-all duration-300 ease-in-out `}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Publications;
