import Image from "next/legacy/image";
import Link from "next/link";
import { ImageProps } from "../../pages/api/schemas/general";
import { articleTag } from "../../pages/api/schemas/enum";

interface CardProps {
  image: ImageProps;
  title: string;
  date: string;
  tag?: articleTag;
  url: string;
  className?: string;
}

const Card: React.FC<CardProps> = ({
  image,
  title,
  date,
  url,
  className,
  tag = "news",
}) => {
  if (typeof window !== "undefined") {
    const cards = document?.querySelectorAll(".card");
    const cursor = document.querySelector(".cursor");
    cards?.forEach((card) =>
      card.addEventListener("mouseover", () => {
        const image = card.querySelector(".card-image");
        const text = card.querySelector(".card-text");
        image?.setAttribute(
          "style",
          "transform: scale(1.1); transition: transform .6s;"
        );
        text?.setAttribute(
          "style",
          "transform: translate(10px, 0px); transition: transform .6s;"
        );

        cursor?.classList.add("active");
        (cursor as Element).innerHTML = "VIEW";
      })
    );
    cards?.forEach((card) =>
      card.addEventListener("mouseout", () => {
        const image = card.querySelector(".card-image");
        const text = card.querySelector(".card-text");
        image?.setAttribute("style", "transition: transform .6s;");
        text?.setAttribute(
          "style",
          "transform: translate(0px, 0px); transition: transform .6s;"
        );

        cursor?.classList.remove("active");
        (cursor as Element).innerHTML = "";
      })
    );
  }
  const placeholderOption = image.blurUrl ? "blur" : undefined;

  return (
    <Link
      itemProp="url"
      href={url}
      target="_blank"
      rel="noreferrer"
      className={`card relative col-span-full col-start-1 row-span-3 gap-3 ${className}`}
    >
      <div className="relative overflow-hidden max-h-full h-5/6 rounded-lg">
        <Image
          src={image.src}
          title={title}
          layout="fill"
          className="card-image object-cover w-full h-full"
          alt={image.alt}
          loading="lazy"
          placeholder={placeholderOption}
          blurDataURL={image.blurUrl}
        />
      </div>
      <div className="px-2 text-black">
        <div className="flex justify-between">
          <div>
            <span className="text-red-700">[</span>
            {tag}
            <span className="text-red-700">]</span>
          </div>
          <div>
            <span className="text-red-700">[</span>
            {date}
            <span className="text-red-700">]</span>
          </div>
        </div>
        <h3 className="card-text w-full text-2xl md:text-3xl pt-2">{title}</h3>
      </div>
    </Link>
  );
};

export default Card;
